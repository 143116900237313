import { Fragment, useState } from "react";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useCompany, useQuery } from "@hooks";

import type { HeadFC } from "gatsby";
import type { QueryReturnHead } from "@typing/types";

import "@stylesPages/Legals.scss";

import { Footer, Header, SEO } from "@components";

interface Props {
  location: {
    search: string;
  }
}

export default function Legals({ location }: Props) {
  const { t } = useTranslation("legals");
  const params = useQuery(location.search);
  const [active, setActive] = useState(params.type ?? "terms");
  const company = useCompany();

  const kyc = [
    {
      title: t("title", { ns: "kyc" }),
      text: t("text-one", { ns: "kyc" })
    },
    {
      title: t("title-two", { ns: "kyc" }),
      text: t("text-two", { ns: "kyc" })
    },
    {
      title: t("title-three", { ns: "kyc" }),
      text: t("text-three", { ns: "kyc" })
    },
    {
      title: t("title-four", { ns: "kyc" }),
      text: t("text-four", { ns: "kyc" })
    },
    {
      title: t("title-five", { ns: "kyc" }),
      text: t("text-five", { ns: "kyc" })
    }
  ];
  const aml = [
    {
      title: t("title", { ns: "aml" }),
      text: t("text-one", { ns: "aml" })
    },
    {
      title: t("title-two", { ns: "aml" }),
      text: t("text-two", { ns: "aml" })
    },
    {
      title: t("title-three", { ns: "aml" }),
      text: t("text-three", { ns: "aml" })
    }
  ];
  const conflict = [
    {
      title: t("title", { ns: "conflict" }),
      text: t("text-one", { ns: "conflict" })
    }
  ];
  const divulgation = [
    {
      title: t("title", { ns: "divulgation" }),
      text: t("text-one", { ns: "divulgation" })
    },
    {
      title: t("title-one", { ns: "divulgation" }),
      text: t("text-one", { ns: "divulgation" })
    },
    {
      title: t("title-two", { ns: "divulgation" }),
      text: t("text-two", { ns: "divulgation" })
    },
    {
      title: t("title-three", { ns: "divulgation" }),
      text: t("text-three", { ns: "divulgation" })
    },
    {
      title: t("title-four", { ns: "divulgation" }),
      text: t("text-four", { ns: "divulgation" })
    },
    {
      title: t("title-five", { ns: "divulgation" }),
      text: t("text-five", { ns: "divulgation" })
    }
  ];
  const execution = [
    {
      title: t("title", { ns: "execution" }),
      text: t("text-one", { ns: "execution" })
    },
    {
      title: t("title-two", { ns: "execution" }),
      text: t("text-two", { ns: "execution" })
    },
    {
      title: t("title-three", { ns: "execution" }),
      text: t("text-three", { ns: "execution" })
    },
    {
      title: t("title-four", { ns: "execution" }),
      text: t("text-four", { ns: "execution" })
    },
    {
      title: t("title-five", { ns: "execution" }),
      text: t("text-five", { ns: "execution" })
    },
    {
      title: t("title-six", { ns: "execution" }),
      text: t("text-six", { ns: "execution" })
    },
    {
      title: t("title-seven", { ns: "execution" }),
      text: t("text-seven", { ns: "execution" })
    },
    {
      title: t("title-eight", { ns: "execution" }),
      text: t("text-eight", { ns: "execution" })
    },
    {
      title: t("title-nine", { ns: "execution" }),
      text: t("text-nine", { ns: "execution" })
    }
  ];
  const privacy = [
    {
      title: t("title", { ns: "privacy" }),
      text: t("text-one", { ns: "privacy" })
    },
    {
      title: t("title-two", { ns: "privacy" }),
      text: t("text-two", { ns: "privacy" })
    },
    {
      title: t("title-three", { ns: "privacy" }),
      text: t("text-three", { ns: "privacy" })
    },
    {
      title: t("title-four", { ns: "privacy" }),
      text: t("text-four", { ns: "privacy" })
    },
    {
      title: t("title-five", { ns: "privacy" }),
      text: t("text-five", { ns: "privacy" })
    }
  ];
  const deposit = [
    {
      title: t("title", { ns: "deposit" }),
      text: t("text-one", { ns: "deposit" })
    },
    {
      title: t("title-two", { ns: "deposit" }),
      text: t("text-two", { ns: "deposit" })
    },
    {
      title: t("title-three", { ns: "deposit" }),
      text: t("text-three", { ns: "deposit" })
    },
    {
      title: t("title-four", { ns: "deposit" }),
      text: t("text-four", { ns: "deposit" })
    },
    {
      title: t("title-five", { ns: "deposit" }),
      text: t("text-five", { ns: "deposit" })
    },
    {
      title: t("title-six", { ns: "deposit" }),
      text: t("text-six", { ns: "deposit" })
    }
  ];
  const use = [
    {
      title: t("title", { ns: "use" }),
      text: t("text-one", { ns: "use" })
    },
    {
      title: t("title-two", { ns: "use" }),
      text: t("text-two", { ns: "use" })
    },
    {
      title: t("title-three", { ns: "use" }),
      text: t("text-three", { ns: "use" })
    },
    {
      title: t("title-four", { ns: "use" }),
      text: t("text-four", { ns: "use" })
    }
  ];
  const terms = [
    {
      title: t("title", { ns: "terms" }),
      text: t("text-one", { ns: "terms" })
    },
    {
      title: t("title-two", { ns: "terms" }),
      text: t("text-two", { ns: "terms" })
    },
    {
      title: t("title-three", { ns: "terms" }),
      text: t("text-three", { ns: "terms" })
    },
    {
      title: t("title-four", { ns: "terms" }),
      text: t("text-four", { ns: "terms" })
    },
    {
      title: t("title-five", { ns: "terms" }),
      text: t("text-five", { ns: "terms" })
    },
    {
      title: t("title-six", { ns: "terms" }),
      text: t("text-six", { ns: "terms" })
    },
    {
      title: t("title-seven", { ns: "terms" }),
      text: t("text-seven", { ns: "terms" })
    },
    {
      title: t("title-eight", { ns: "terms" }),
      text: t("text-eight", { ns: "terms" })
    },
    {
      title: t("title-nine", { ns: "terms" }),
      text: t("text-nine", { ns: "terms" })
    },
    {
      title: t("title-ten", { ns: "terms" }),
      text: t("text-ten", { ns: "terms" })
    },
    {
      title: t("title-eleven", { ns: "terms" }),
      text: t("text-eleven", { ns: "terms" })
    },
    {
      title: t("title-twelve", { ns: "terms" }),
      text: t("text-twelve", { ns: "terms" })
    },
    {
      title: t("title-thirteen", { ns: "terms" }),
      text: t("text-thirteen", { ns: "terms" })
    },
    {
      title: t("title-fourteen", { ns: "terms" }),
      text: t("text-fourteen", { ns: "terms" })
    },
    {
      title: t("title-fifteen", { ns: "terms" }),
      text: t("text-fifteen", { ns: "terms" })
    },
    {
      title: t("title-sixteen", { ns: "terms" }),
      text: t("text-sixteen", { ns: "terms" })
    },
    {
      title: t("title-seventeen", { ns: "terms" }),
      text: t("text-seventeen", { ns: "terms" })
    },
    {
      title: "",
      text: company
    }
  ];

  const tabs: Record<string, { title: string; text: string; }[]> = {
    kyc,
    aml,
    conflict,
    divulgation,
    execution,
    privacy,
    deposit,
    use,
    terms
  };

  return (
    <>
      <Header />
      <section className="legals">
        <div className="legals__tabs">
          {Object.keys(tabs).map((tab, index) => (
            <Link
              key={`${index + 0}`}
              className={`legals__tab ${active === tab ? " legals__tab--active" : ""}`}
              to={`/legals?type=${tab}`}
              onClick={() => setActive(tab)}
            >
              {t("title", { ns: tab })}
            </Link>
          ))}
        </div>
        {tabs[active].map(({ title, text }, index) => (
          <Fragment key={`${index + 0}`}>
            <h1 className="legals__title">{title}</h1>
            <p
              className="legals__text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </Fragment>
        ))}
      </section>
      <Footer />
    </>
  );
}

export const Head: HeadFC<QueryReturnHead> = ({ data: { locales } }) => {
  const legals = locales.edges.find(({ node }) => (node.ns === "legals"));

  const { "seo-title": titleSeo } = legals ? JSON.parse(legals.node.data) : {
    "seo-title": ""
  };

  return (
    <SEO title={titleSeo} />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
